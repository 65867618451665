import { useStore } from "./store";

export const config = () => {
  const token = useStore.getState().user?.token;

  if (token !== undefined) {
    return {
      headers: { Authorization: `Token ${token}` },
    };
  }

  return {};
};
