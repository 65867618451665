/* eslint-disable no-nested-ternary */
import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Container,
  CssBaseline,
  Typography,
} from "@mui/material";
import axios from "axios";

import { AppLayout } from "../components/layout/AppLayout";
import { useStore } from "../store";
import { config } from "../api";

type TType = {
  ttype: string;
  count: number;
};

const TListItem = function X({ ttype, count }: TType) {
  return (
    <ListItem>
      <ListItemButton href={`/#/app/complete/${ttype}`} component="a">
        <ListItemText primary={ttype} secondary={`~${count} tasks`} />
      </ListItemButton>
    </ListItem>
  );
};

export const TaskListPage = function X(): React.ReactElement {
  const [ttypes, setTtypes] = React.useState<TType[] | null>(null);
  const currentUserID = useStore((store) => store.user.id);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchTasks() {
      try {
        const cancelResp = await axios.delete(
          `/users/${currentUserID}/reservations`,
          config()
        );
        if (cancelResp.status !== 200) {
          console.error(
            "could not cancel previous reservations: ",
            cancelResp.data
          );
        }
      } catch (error) {
        console.error("could not cancel previous reservations: ", error);
      }
      const tasksResp = await axios.get("/tasks", config());
      setTtypes(tasksResp.data.tasks);
      if (tasksResp.data.filtering) {
        console.log("available tasks filter applied");
      }
      setIsLoading(false);
    }

    fetchTasks();

    // TODO: refresh the available task list on a clock tick.
  }, [currentUserID]);

  return (
    <AppLayout>
      <Container component="main">
        <CssBaseline />
        <Typography variant="h3">Available tasks</Typography>
        {isLoading ? (
          <Typography variant="h4">Please wait, loading ...</Typography>
        ) : ttypes !== null && ttypes.length > 0 ? (
          <List>
            {ttypes?.map((i) => (
              <TListItem ttype={i.ttype} count={i.count} key={i.ttype} />
            ))}
          </List>
        ) : (
          <Typography variant="h5">No tasks</Typography>
        )}
      </Container>
    </AppLayout>
  );
};
