import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, Container, CssBaseline, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { AppLayout } from "../components/layout/AppLayout";
import { config } from "../api";

type Completion = {
  t: string;
  g: string;
  d: number;
  c: string;
};

type Summary = {
  count: number;
  seconds: number;
};

const DaySummary = function DaySummary({
  completions,
}: {
  completions: Completion[];
}) {
  const summaries = new Map<string, Summary>();

  completions.forEach((c) => {
    const curr = summaries.get(c.t) || { count: 0, seconds: 0 };
    summaries.set(c.t, { count: curr.count + 1, seconds: curr.seconds + c.d });
  });

  const cols = [
    { field: "task", headerName: "Task", width: 300 },
    { field: "count", headerName: "Count", width: 150 },
    { field: "time", headerName: "Total Time", width: 150 },
    { field: "mean", headerName: "Mean", width: 150 },
  ];
  const rows = Array.from(summaries).map(([t, s]) => ({
    id: t,
    task: t,
    count: s.count,
    time: new Date(s.seconds * 1000).toUTCString().split(" ")[4],
    mean: new Date((s.seconds / s.count) * 1000).toUTCString().split(" ")[4],
  }));

  console.log(rows);

  return (
    <Box style={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={cols}
        density="compact"
        // pageSize={6}
        initialState={{
          sorting: {
            sortModel: [{ field: "task", sort: "desc" }],
          },
        }}
        disableSelectionOnClick
        hideFooter
        autoHeight
      />
    </Box>
  );
};

export const CompletionsPage = function CompletionsPage() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [completions, setCompletions] = React.useState<
    Map<string, Completion[]>
  >(new Map());
  const { group } = useParams();

  React.useEffect(() => {
    async function fetchData() {
      const r = await axios.get(`/completions/${group}`, config());
      const cs = r.data.completions.map((x: Completion) => ({
        t: x.t,
        g: x.g,
        d: x.d,
        c: x.c.substring(0, 10),
      }));
      const completionsByDate = new Map<string, Completion[]>();
      cs.forEach((completion: Completion) => {
        const curr = completionsByDate.get(completion.c) || [];
        curr.push(completion);
        completionsByDate.set(completion.c, curr);
      });
      setCompletions(completionsByDate);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  console.log(completions);

  return (
    <AppLayout>
      <Container component="main">
        <CssBaseline />
        <Typography variant="h4">Completions for {group}</Typography>
        {isLoading ? (
          <Typography variant="h4">Please wait, loading ...</Typography>
        ) : (
          <Stack spacing={2}>
            {Array.from(completions)
              .sort((a, b) => b[0].localeCompare(a[0]))
              .map(([k, lst]) => (
                <Box id={k}>
                  <Typography variant="h6">{k}</Typography>
                  <DaySummary completions={lst} />
                </Box>
              ))}
          </Stack>
        )}
      </Container>
    </AppLayout>
  );
};
