import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";
import {
  ClassificationTask,
  // TClass,
  getType,
  TaskType,
  Completion,
} from "../tasks";
import { InputDisplay } from "./InputDisplay";

export const Classify = function ({
  task,
  reservationID,
  onCompletion,
  onBadInput,
}: {
  task: ClassificationTask;
  reservationID: string;
  onCompletion: (id: string, spec: Completion) => void;
  onBadInput: (id: string, duration: number) => void;
}): React.ReactElement {
  const { taskType, taskInput, classes } = task;
  const [v, setV] = React.useState<string>(task.classes[0].value);
  const [t0State, setT0State] = React.useState<Date>(new Date(Date.now()));
  const [locked, setLocked] = React.useState(false);
  const completeBtnRef = React.createRef<HTMLButtonElement>();

  React.useEffect(() => {
    setV(task.classes[0].value);
    setT0State(new Date(Date.now()));
    setLocked(false);
  }, [reservationID, task]);

  for (let i = 0; i < classes.length; i += 1) {
    const { hotkey, value } = classes[i];
    if (hotkey) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useHotkeys(
        hotkey,
        () => {
          setV(value);
        },
        undefined,
        [completeBtnRef, setV, value]
      );
    }
  }

  React.useEffect(() => {
    completeBtnRef.current?.focus();
  }, [v, completeBtnRef]);

  const handleCompletion = () => {
    if (locked) {
      return;
    }
    setLocked(true);

    const payload = {
      type: TaskType.Classification,
      duration: Math.abs((Date.now() - t0State.getTime()) / 1000),
      completion: { value: v },
    };
    onCompletion(reservationID, payload);
  };

  const handleError = () => {
    if (locked) {
      return;
    }
    setLocked(true);

    onBadInput(
      reservationID,
      Math.abs((Date.now() - t0State.getTime()) / 1000)
    );
  };

  if (getType(taskType) !== TaskType.Classification) {
    return <p>Not a classification task! Task type is {getType(taskType)}</p>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <InputDisplay input={taskInput} />
      </Grid>

      <Grid item xs={3}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Class</FormLabel>
          <RadioGroup
            aria-label="class"
            defaultValue="female"
            name="radio-buttons-group"
            value={v}
            onChange={(e) => {
              setV(e.target.value);
              // document.getElementById("complete-btn")?.focus();
            }}
          >
            {classes.map(({ label, value, hotkey }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={`[${hotkey}] ${label}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {/* <ClassSelector classes={classes} onChange={setV} /> */}
        <Grid item container spacing={2}>
          <Grid item md={6}>
            <Button
              ref={completeBtnRef}
              disabled={locked}
              variant="contained"
              onClick={() => handleCompletion()}
            >
              Complete
            </Button>
          </Grid>
          <Grid item md={6}>
            <Button disabled={locked} color="error" onClick={handleError}>
              Report error
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
