import React from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import applyCaseMiddleware from "axios-case-converter";
// import shallow from "zustand/shallow";

// import { AppCtx } from "./context";
// import { useStore } from "./store";

export class NetworkError extends Error {
  constructor() {
    super("network-error");
    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}

export const WithAPI = function ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  // const ctx = React.useContext(AppCtx);
  // const [token, clearUser] = useStore(
  //   (state) => [state.user.token, state.clearUser],
  //   shallow
  // );
  // const clearUser = useStore((s) => s.clearUser);
  // const navigate = useNavigate();

  // React.useMemo(() => {
  // applyCaseMiddleware(axios);
  // axios.interceptors.request.use(
  //   (req) => {
  //     if (!req.headers) {
  //       req.headers = {};
  //     }
  //     // The API only accepts json requests.
  //     req.headers["Content-Type"] = "application/json";
  //     req.headers["Access-Control-Allow-Origin"] = ctx.apiURL;

  //     if (!req.url) {
  //       throw new Error("wap:no-url");
  //     }
  //     req.url = new URL(req.url, ctx.apiURL).toString();
  //     if (
  //       req.url &&
  //       !(
  //         req.url.endsWith("/auth/verify") ||
  //         req.url.endsWith("/auth/sign-in")
  //       )
  //     ) {
  //       // Trying to access a protected resource.
  //       console.error("url", `${req.method}::${req.url}`, "wap:token", token);
  //       req.headers.Authorization = `Token ${token}`;
  //     }
  //     return req;
  //   },
  //   (error) => {
  //     console.error("intercepted", error);
  //     return Promise.reject(error);
  //   }
  // );

  //   axios.interceptors.response.use(undefined, (err) => {
  //     if (err.response === undefined) {
  //       return Promise.reject(new NetworkError());
  //     }
  //     if (err.response.status === 401) {
  //       // Reset authentication. The access token could be expired.
  //       console.error("clearing user");
  //       clearUser();
  //       navigate("/auth/sign-in");
  //     }
  //     return Promise.reject(err);
  //   });
  // }, [clearUser, navigate]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
