import create from "zustand";
import { persist } from "zustand/middleware";

export type User = {
  id: string;
  token: string;
  email: string;
  fname: string;
  lname: string;
};

type Store = {
  user: User;
  setUser: (u: User) => void;
  clearUser: () => void;
};

const zeroUser: User = { id: "", token: "", email: "", fname: "", lname: "" };

export const useStore = create<Store>(
  persist(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (set, get) => ({
      user: zeroUser,
      setUser: (u: User) => set({ user: u }),
      clearUser: () => set({ user: zeroUser }),
    }),
    { name: "trular-labelling" }
  )
);
