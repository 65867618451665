import React from "react";
import { useRoutes } from "react-router-dom";
import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";

import "./App.css";
import { useStore } from "./store";
import { AppCtx, IAppContext } from "./context";
import { routes } from "./routes";

const apiURL = {
  // TODO: write the correct production API URL.
  production: "https://mp-labels-api-tapk4tpvqa-uc.a.run.app/api/v1",
  // local: "https://mp-labels-api-testing-tapk4tpvqa-uc.a.run.app/api/v1",
  // FIXME: re-enable the local URL.
  local: "https://mp-labels-api-testing-tapk4tpvqa-uc.a.run.app/api/v1",
  // local: "http://127.0.0.1:5003/api/v1",
};

const currentApiUrl =
  process.env.NODE_ENV === "production" ? apiURL.production : apiURL.local;

const appContext: IAppContext = {
  apiURL: currentApiUrl,
};

applyCaseMiddleware(axios);
axios.defaults.baseURL = currentApiUrl;
axios.defaults.headers.common["Content-Type"] = "application/json";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = currentApiUrl;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      const { clearUser } = useStore.getState();
      clearUser();
    }
    return Promise.reject(err);
  }
);

const App = function App(): React.ReactElement {
  const token = useStore((state) => state.user.token);
  const routing = useRoutes(routes(token !== ""));

  // useEffect(() => {
  //   axios.defaults.headers.common.Authorization = `Token ${token}`;
  // }, [token]);

  return <AppCtx.Provider value={appContext}>{routing}</AppCtx.Provider>;
};

export default App;
