/* eslint-disable react/require-default-props */
import React from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@mui/icons-material";
import DateAdapter from "@mui/lab/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { Moment } from "moment";

// import { useHotkeys } from "react-hotkeys-hook";
import {
  TField,
  getType,
  TaskType,
  TranslationTask,
  Completion,
} from "../tasks";
import { InputDisplay } from "./InputDisplay";

const ReservationContext = React.createContext<string>("");
const FirstComponentContext = React.createContext<string>("");

// type VoidFn = () => void;
// // eslint-disable-next-line @typescript-eslint/no-empty-function
// const voidfn = (): void => {};

// const Repeater = function Repeater({
//   max,
//   min,
//   onAdd = voidfn,
//   onDel = voidfn,
// }: {
//   max: number;
//   min: number;
//   onAdd?: VoidFn;
//   onDel?: VoidFn;
// }) {
//   const [n, setN] = React.useState<number>(min);

//   return (
//     <div className="flex flex-row gap-2">
//       <Button
//         size="small"
//         variant="contained"
//         startIcon={<AddIcon />}
//         color="success"
//         onClick={() => {
//           setN((c) => c + 1);
//           onAdd();
//         }}
//         disabled={n >= max}
//       >
//         add
//       </Button>
//       <Button
//         size="small"
//         variant="contained"
//         color="error"
//         startIcon={<Delete />}
//         onClick={() => {
//           setN((c) => c - 1);
//           onDel();
//         }}
//         disabled={n <= min}
//       >
//         remove
//       </Button>
//     </div>
//   );
// };

type FieldGeneratorProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  idx: number;
  // FIXME: check if this is a bug in the compiler or I'm doing something wrong.
  // There is an Error if the next lint-disable comment is not present.
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
  onChange: (name: string, value: unknown) => void;
};

type FieldWrapperProps = {
  generator: (arg0: FieldGeneratorProps) => React.ReactElement;
  field: TField;
  onChange: (name: string, value: unknown) => void;
};

// TODO: I'm sure there is a better way to organise this code, it is quite
// repetitive. I did it this way to make changes easier at the beginning,
// but now it looks stable.
const FieldWrapper = function FieldWrapper({
  generator,
  field,
  onChange,
}: FieldWrapperProps) {
  const name = React.useCallback(
    (idx: number) => `${field.name}-${idx}`,
    [field.name]
  );
  const label = React.useCallback(
    (idx: number) => `${field.label}-${idx}`,
    [field.label]
  );

  const [fields, setFields] = React.useState<React.ReactElement[]>([]);

  React.useEffect(() => {
    const minFields = [];
    for (let i = 0; i < field.minCount; i += 1) {
      minFields.push(
        generator({ idx: i, name: name(i), label: label(i), onChange })
      );
    }
    setFields(minFields);
  }, [field]);

  return (
    <div className="flex flex-col items-start gap-4 border p-4 rounded">
      <div>{field.label}</div>
      {fields.map((f) => (
        <React.Fragment key={f.props.label}>{f}</React.Fragment>
      ))}

      <div className="ml-auto">
        <div className="flex flex-row gap-2">
          <Button
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            color="success"
            onClick={() => {
              setFields((fs) => [
                ...fs,
                generator({
                  idx: fs.length,
                  name: name(fs.length),
                  label: label(fs.length),
                  onChange,
                }),
              ]);
            }}
            disabled={fields.length >= field.maxCount}
          >
            add
          </Button>
          <Button
            size="small"
            variant="contained"
            color="error"
            startIcon={<Delete />}
            onClick={() => {
              setFields((fs) => fs.slice(0, fs.length - 1));
              onChange(name(fields.length - 1), null);
            }}
            disabled={fields.length <= field.minCount}
          >
            remove
          </Button>
        </div>
        {/* <Repeater
          min={field.minCount}
          max={field.maxCount}
          onDel={() => {
            setFields((fs) => fs.slice(0, fs.length - 1));
            onChange(name(fields.length - 1), null);
          }}
          onAdd={() =>
            setFields((fs) => [
              ...fs,
              generator({
                idx: fs.length,
                name: name(fs.length),
                label: label(fs.length),
                onChange,
              }),
            ])
          }
        /> */}
      </div>
    </div>
  );
};

const StreetAddressField = function StreetAddressField({
  idx,
  name,
  onChange,
}: FieldGeneratorProps) {
  const [streetName, setStreetName] = React.useState<string>("");
  const [streetNumber, setStreetNumber] = React.useState<number>();
  const reservationID = React.useContext(ReservationContext);

  React.useEffect(() => {
    setStreetName("");
    setStreetNumber(undefined);
  }, [reservationID]);

  React.useEffect(() => {
    const canonicalName = name.substring(0, name.lastIndexOf("-"));
    onChange(name, {
      name: canonicalName,
      fieldType: "FIELD_STREET_ADDR",
      value: [streetName, streetNumber === undefined ? -1 : streetNumber],
    });
  }, [name, onChange, streetName, streetNumber]);

  return (
    <div className="flex flex-row w-full items-center gap-4">
      <div className="w-4">#{idx}</div>

      <TextField
        className="flex-grow"
        size="small"
        label="Rua"
        id={`${name}-snam`}
        value={streetName}
        error={streetName === ""}
        onChange={(e) => setStreetName(e.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start">🔤</InputAdornment>,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
      <TextField
        className="w-24"
        size="small"
        label="Número"
        id={`${name}-snum`}
        error={streetNumber !== undefined && streetNumber < 0}
        value={streetNumber === undefined ? "" : streetNumber}
        onChange={(e) => {
          const n = parseInt(e.target.value, 10);
          if (!Number.isNaN(n) && Number.isInteger(n)) {
            setStreetNumber(n);
          } else {
            setStreetNumber(undefined);
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">🔢</InputAdornment>,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};

const StringField = function StringField({
  idx,
  name,
  label,
  onChange,
}: FieldGeneratorProps) {
  const [value, setValue] = React.useState<string>("");
  const reservationID = React.useContext(ReservationContext);

  React.useEffect(() => {
    setValue("");
  }, [reservationID]);

  React.useEffect(() => {
    const canonicalName = name.substring(0, name.lastIndexOf("-"));
    onChange(name, {
      name: canonicalName,
      fieldType: "FIELD_STR",
      value,
    });
  }, [value, name, onChange]);

  return (
    <div className="flex flex-row w-full items-center gap-4">
      <div className="w-4">#{idx}</div>
      <TextField
        className="flex-grow"
        error={value === ""}
        key={name}
        size="small"
        label={label}
        id={name}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">🔤</InputAdornment>,
        }}
      />
    </div>
  );
};

const RegRefField = function RegRefField({
  idx,
  name,
  label,
  onChange,
}: FieldGeneratorProps) {
  // FIXME: validation broken
  const defaultType = "R";
  const [index, setIndex] = React.useState<number>();
  const [type, setType] = React.useState<string>(defaultType);
  const reservationID = React.useContext(ReservationContext);
  const firstCmpName = React.useContext(FirstComponentContext);

  React.useEffect(() => {
    setIndex(undefined);
    setType(defaultType);
  }, [reservationID]);

  React.useEffect(() => {
    const canonicalName = name.substring(0, name.lastIndexOf("-"));
    onChange(name, {
      name: canonicalName,
      fieldType: "FIELD_REG_REF",
      value: [type, index],
    });
  }, [name, onChange, type, index]);

  return (
    <div className="flex flex-row w-full items-center gap-4">
      <div className="w-4">#{idx}</div>
      <Select
        size="small"
        onChange={(e) => setType(e.target.value)}
        value={type}
        autoWidth
      >
        <MenuItem value="R">R</MenuItem>
        <MenuItem value="AV">AV</MenuItem>
      </Select>
      <TextField
        autoFocus={firstCmpName === name.substring(0, name.lastIndexOf("-"))}
        className="flex-grow"
        size="small"
        label={label}
        id={name}
        error={index === undefined || index < 0}
        value={index === undefined ? "" : index}
        onChange={(e) => {
          const n = parseInt(e.target.value, 10);
          if (!Number.isNaN(n) && Number.isInteger(n)) {
            setIndex(n);
          } else {
            setIndex(undefined);
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">🔢</InputAdornment>,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};

const IntField = function IntField({
  idx,
  name,
  label,
  onChange,
}: FieldGeneratorProps) {
  const [value, setValue] = React.useState<number>();
  const reservationID = React.useContext(ReservationContext);

  React.useEffect(() => {
    setValue(undefined);
  }, [reservationID]);

  React.useEffect(() => {
    const canonicalName = name.substring(0, name.lastIndexOf("-"));
    onChange(name, {
      name: canonicalName,
      fieldType: "FIELD_INT",
      value,
    });
  }, [name, onChange, value]);

  return (
    <div className="flex flex-row w-full items-center gap-4">
      <div className="w-4">#{idx}</div>
      <TextField
        className="flex-grow"
        size="small"
        label={label}
        id={name}
        value={value === undefined ? "" : value}
        error={value === undefined}
        onChange={(e) => {
          const n = parseInt(e.target.value, 10);
          if (!Number.isNaN(n) && Number.isInteger(n)) {
            setValue(n);
          } else {
            setValue(undefined);
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">🔢</InputAdornment>,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};

const timeToDateISOString = (mmnt: Moment): string | null | undefined => {
  try {
    const t: Date = mmnt.toDate();
    const d = t.getDate().toString().padStart(2, "0");
    const m = (t.getMonth() + 1).toString().padStart(2, "0");
    const y = t.getFullYear().toString();
    return `${y}-${m}-${d}T00:00:00.000Z`;
  } catch {
    return null;
  }
};

const DateField = function DateField({
  idx,
  name,
  label,
  onChange,
}: FieldGeneratorProps) {
  const [value, setValue] = React.useState<Moment | null>(null);
  const reservationID = React.useContext(ReservationContext);

  React.useEffect(() => {
    setValue(null);
  }, [reservationID]);

  React.useEffect(() => {
    const canonicalName = name.substring(0, name.lastIndexOf("-"));
    onChange(name, {
      name: canonicalName,
      fieldType: "FIELD_DATE",
      value: value ? timeToDateISOString(value) : null,
    });
  }, [name, onChange, value]);

  const error = value === undefined || value === null;

  return (
    <div className="flex flex-row w-full items-center gap-4">
      <div className="w-4">#{idx}</div>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DesktopDatePicker
          label={label}
          value={value}
          onChange={setValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">📅</InputAdornment>
            ),
          }}
          className="flex-grow"
          inputFormat="DD/MM/YYYY"
          disableOpenPicker
          renderInput={(params) => (
            <TextField
              id={name}
              error={error}
              size="small"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

const CurrencyField = function CurrencyField({
  idx,
  name,
  label,
  onChange,
}: FieldGeneratorProps) {
  const [value, setValue] = React.useState<number>();
  const [fmt, setFmt] = React.useState<string>();
  const reservationID = React.useContext(ReservationContext);

  React.useEffect(() => {
    setValue(undefined);
    setFmt(undefined);
  }, [reservationID]);

  React.useEffect(() => {
    const canonicalName = name.substring(0, name.lastIndexOf("-"));
    onChange(name, {
      name: canonicalName,
      fieldType: "FIELD_MONEY",
      value,
    });
  }, [name, onChange, value]);

  const formatter = new Intl.NumberFormat("pt-BR");

  return (
    <div className="flex flex-row w-full items-center gap-4">
      <div className="w-4">#{idx}</div>
      <TextField
        className="flex-grow"
        size="small"
        label={label}
        id={name}
        value={fmt === undefined ? "" : fmt}
        error={value === undefined}
        onChange={(e) => {
          const n = parseInt(e.target.value.split(".").join(""), 10);
          if (!Number.isNaN(n) && Number.isInteger(n)) {
            setValue(n);
            setFmt(formatter.format(n));
          } else {
            setValue(undefined);
            setFmt(undefined);
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};

const isFloat = (s: string) => /^[0-9]+(,[0-9]+)?,?$/.test(s);

const DoubleField = function DoubleField({
  idx,
  name,
  label,
  onChange,
}: FieldGeneratorProps) {
  const [value, setValue] = React.useState<number>();
  const [fmt, setFmt] = React.useState<string>();
  const reservationID = React.useContext(ReservationContext);

  React.useEffect(() => {
    setValue(undefined);
    setFmt(undefined);
  }, [reservationID]);

  React.useEffect(() => {
    const canonicalName = name.substring(0, name.lastIndexOf("-"));
    onChange(name, {
      name: canonicalName,
      fieldType: "FIELD_DOUBLE",
      value,
    });
  }, [name, onChange, value]);

  return (
    <div className="flex flex-row w-full items-center gap-4">
      <div className="w-4">#{idx}</div>

      <TextField
        className="flex-grow"
        size="small"
        label={label}
        id={name}
        value={fmt === undefined ? "" : fmt}
        error={value === undefined}
        onChange={(e) => {
          if (!isFloat(e.target.value)) {
            return;
          }
          const n = parseFloat(e.target.value.replace(",", "."));
          if (!Number.isNaN(n)) {
            setValue(n);
            setFmt(e.target.value);
          } else {
            setValue(undefined);
            setFmt(undefined);
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">D🔢</InputAdornment>,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};

const FieldGen = function FieldGen({
  field,
  onChange,
}: {
  field: TField;
  onChange: (name: string, value: unknown) => void;
}) {
  return (
    <FieldWrapper
      field={field}
      onChange={onChange}
      // FIXME: fix the code to remove this linter exception.
      // eslint-disable-next-line react/no-unstable-nested-components
      generator={({
        idx,
        name,
        label,
        onChange: handleChange,
      }: FieldGeneratorProps) => {
        switch (field.fieldType) {
          case "FIELD_STR":
            return (
              <StringField
                idx={idx + 1}
                name={name}
                label={label}
                onChange={handleChange}
              />
            );
          case "FIELD_INT":
            return (
              <IntField
                idx={idx + 1}
                name={name}
                label={label}
                onChange={handleChange}
              />
            );
          case "FIELD_DOUBLE":
            return (
              <DoubleField
                idx={idx + 1}
                name={name}
                label={label}
                onChange={handleChange}
              />
            );
          case "FIELD_DATE":
            return (
              <DateField
                idx={idx + 1}
                name={name}
                label={label}
                onChange={handleChange}
              />
            );
          case "FIELD_REG_REF":
            return (
              <RegRefField
                idx={idx + 1}
                name={name}
                label={label}
                onChange={handleChange}
              />
            );
          case "FIELD_MONEY":
            return (
              <CurrencyField
                idx={idx + 1}
                name={name}
                label={label}
                onChange={handleChange}
              />
            );
          case "FIELD_STREET_ADDR":
            return (
              <StreetAddressField
                idx={idx + 1}
                name={name}
                label={label}
                onChange={handleChange}
              />
            );
          default:
            return <p>{`unknown field with type: ${field.fieldType}`}</p>;
        }
      }}
    />
  );
};

const TranslationFields = function TranslationFields({
  fields,
  onChange,
}: {
  fields: TField[];
  onChange: (name: string, value: unknown) => void;
}) {
  if (fields.length < 1) {
    return <p>INVALID: no fields to translate</p>;
  }

  return (
    <Box
      sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}
      component="form"
      autoComplete="off"
    >
      <FirstComponentContext.Provider value={fields[0].name}>
        {fields.map((f) => {
          return <FieldGen key={f.name} field={f} onChange={onChange} />;
        })}
      </FirstComponentContext.Provider>
    </Box>
  );
};

export const Translate = function Translate({
  task,
  reservationID,
  onCompletion,
  onBadInput,
}: {
  task: TranslationTask;
  reservationID: string;
  onCompletion: (id: string, spec: Completion) => void;
  onBadInput: (id: string, duration: number) => void;
}): React.ReactElement {
  const { taskType, taskInput, fields } = task;
  const [t0State, setT0State] = React.useState<Date>(new Date(Date.now()));
  const [vs, setVS] = React.useState<Record<string, unknown>>({});
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [locked, setLocked] = React.useState(false);

  React.useEffect(() => {
    setT0State(new Date(Date.now()));
    setVS({});
    setLocked(false);
    const input = document.querySelector("input[type=text]");
    if (input) {
      (input as HTMLElement).focus();
    }
  }, [reservationID]);

  React.useEffect(() => {
    // Effect for validating translations.
    interface Translation {
      fieldType: string;
      name: string;
      value: null | number | string | [string, number];
    }

    const entries = Object.values<Translation>(
      vs as Record<string, Translation>
    );

    const sumMin = task.fields.reduce((curr, f) => curr + f.minCount, 0);
    if (entries.length === 0 && sumMin > 0) {
      setIsValid(false);
      return;
    }

    for (let i = 0; i < entries.length; i += 1) {
      // Any translation reporting an error is valid.
      const { fieldType, value } = entries[i] as Translation;
      switch (fieldType) {
        case "FIELD_INT":
          if (value === null || value === undefined) {
            setIsValid(false);
            return;
          }
          break;
        case "FIELD_DOUBLE":
          if (value === null || value === undefined) {
            setIsValid(false);
            return;
          }
          break;
        case "FIELD_DATE":
          if (value === null || value === undefined) {
            setIsValid(false);
            return;
          }
          break;
        case "FIELD_MONEY": // (v: number | null) => v === null,
          if (value === null || value === undefined) {
            setIsValid(false);
            return;
          }
          break;
        case "FIELD_REG_REF": {
          const [r, ix] = value as [string, number];
          if ((r !== "R" && r !== "AV") || ix === undefined || ix < 0) {
            setIsValid(false);
            return;
          }
          break;
        }
        case "FIELD_STREET_ADDR": {
          const [name, num] = value as [string, number];
          if (
            name === "" ||
            (num !== undefined && !Number.isNaN(num) && !Number.isInteger(num))
          ) {
            setIsValid(false);
            return;
          }
          break;
        }
        case "FIELD_STR":
          if (value === undefined || value === null || value === "") {
            setIsValid(false);
            return;
          }
          break;
        default:
          setIsValid(false);
          console.error("unknown field type");
      }
    }
    setIsValid(true);
  }, [vs]);

  const handleChange = (name: string, value: unknown | null) => {
    if (value === null) {
      // Used for removing fields.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setVS(({ [name]: omit, ...rest }) => rest);
    } else {
      setVS((state) => ({ ...state, [name]: value }));
    }
  };

  const handleCompletion = () => {
    if (locked) {
      return;
    }
    setLocked(true);
    onCompletion(reservationID, {
      type: TaskType.Translation,
      duration: Math.abs((Date.now() - t0State.getTime()) / 1000),
      completion: { fields: vs },
    });
  };

  const handleError = () => {
    if (locked) {
      return;
    }
    setLocked(true);
    onBadInput(
      reservationID,
      Math.abs((Date.now() - t0State.getTime()) / 1000)
    );
  };

  if (getType(taskType) !== TaskType.Translation) {
    return <p>Not a translation task! Task type is {getType(taskType)}</p>;
  }

  return (
    <Box
      sx={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 5 }}
      style={{ maxHeight: "80vh" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={7} style={{ maxHeight: "80vh", overflow: "auto" }}>
          <InputDisplay input={taskInput} />
        </Grid>

        <Grid item xs={5} style={{ maxHeight: "80vh", overflow: "auto" }}>
          <ReservationContext.Provider value={reservationID}>
            <TranslationFields fields={fields} onChange={handleChange} />
          </ReservationContext.Provider>
          <Grid item container spacing={2}>
            <Grid item md={6}>
              <Button
                id="complete-btn"
                disabled={locked || !isValid}
                variant="contained"
                onClick={handleCompletion}
              >
                Complete
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button disabled={locked} color="error" onClick={handleError}>
                Report error
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
