import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppLayout } from "../components/layout/AppLayout";
import { Classify } from "../components/Classify";
import { Translate } from "../components/Translate";
import { useStore } from "../store";
import {
  Reservation,
  Task,
  TaskType,
  ClassificationTask,
  getType,
  TranslationTask,
  Completion,
  SegmentationTask,
} from "../tasks";
import { SegmentImage } from "../components/ImageSegmentation";
import { NetworkError } from "../WithAPI";
import { M } from "../messages";
import { config } from "../api";

const CompleteReservation = function X({
  reservation,
  t,
  onCompletion,
  onBadInput,
}: {
  reservation: Reservation;
  t: Task;
  onBadInput: (id: string, duration: number) => void;
  onCompletion: (id: string, spec: Completion) => void;
}): React.ReactElement {
  if (t === undefined) {
    return <p>could not load the requested task</p>;
  }

  switch (getType(t.taskType)) {
    case TaskType.Classification:
      return (
        <Classify
          task={t as ClassificationTask}
          reservationID={reservation.id}
          onCompletion={onCompletion}
          onBadInput={onBadInput}
        />
      );
    case TaskType.Translation:
      return (
        <Translate
          task={t as TranslationTask}
          reservationID={reservation.id}
          onCompletion={onCompletion}
          onBadInput={onBadInput}
        />
      );
    case TaskType.Segmentation:
      return (
        <SegmentImage
          height={900}
          task={t as SegmentationTask}
          reservationID={reservation.id}
          onCompletion={onCompletion}
          onBadInput={onBadInput}
        />
      );
    default:
      return <p>Task type not implemented: {`${t.taskType}`}</p>;
  }
};

const TopBar = function TopBar({
  expiration,
  onStop,
  onTimeout,
}: {
  expiration: Date;
  onStop: () => void;
  onTimeout: () => void;
}) {
  const calculateTimeLeft = () => {
    console.log("expiration", expiration);
    console.log("exptime", new Date(expiration));
    console.log("curtime", new Date());
    const diff = new Date(expiration).getTime() - new Date().getTime();
    if (diff > 0) {
      return {
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / 1000 / 60) % 60),
        seconds: Math.floor((diff / 1000) % 60),
      };
    }
    return null;
  };

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const tl = calculateTimeLeft();
    if (tl === null) {
      // calculateTimeLeft only returns a zero value when time is out.
      onTimeout();
    } else {
      const to = setTimeout(() => setTimeLeft(tl), 1000);
      return () => {
        clearTimeout(to);
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [timeLeft]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 2,
        paddingBottom: 2,
        border: 1,
      }}
    >
      <Typography>
        {timeLeft ? (
          <>
            TimeLeft{" "}
            <strong>
              {timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s
            </strong>
          </>
        ) : (
          "Time's up"
        )}
      </Typography>
      <Button color="warning" variant="outlined" onClick={() => onStop()}>
        Stop Working
      </Button>
    </Box>
  );
};

const hasProp = (obj: any, prop: string) =>
  Object.prototype.hasOwnProperty.call(obj, prop);

type TnR = {
  task: Task;
  reservation: Reservation;
};

export const CompleteTaskPage = function X(): React.ReactElement {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [stoppingWork, setStoppingWork] = React.useState<boolean>(false);
  const [criticalError, setCriticalError] = React.useState<string>();
  // const [validReservations, setValidReservations] = React.useState<
  //   Reservation[]
  // >([]);
  const [queue, setQueue] = React.useState<TnR[]>([]);
  const [current, setCurrent] = React.useState<TnR>();
  // const [validTasks, setValidTasks] = React.useState<Task[]>([]);

  const userID = useStore((store) => store.user.id);
  const { taskType } = useParams();
  const navigate = useNavigate();

  // Keep the reservations buffer filled.
  React.useEffect(() => {
    if (queue.length < 5) {
      axios
        .post<{ reservation: Reservation }>(
          "/reservations",
          { type: taskType },
          config()
        )
        .then(
          (resp) => {
            if (resp.status === 204) {
              // No tasks of this type at this point.
              if (queue.length === 0) {
                // There are no reservations to work on.
                alert("no more tasks! :)");
                navigate("/");
                return;
              }
              // There is still an assignment in the buffer, continue for now.
              return;
            }

            // preload the task.
            axios.get(`/tasks/${resp.data.reservation.taskId}`, config()).then(
              (r) => {
                if (r.data.error) {
                  console.log("preloading the task", r.data.error);
                }
                setQueue((q) => [
                  ...q,
                  { reservation: resp.data.reservation, task: r.data.task },
                ]);
                if (!loaded) {
                  setLoaded(true);
                }
              },
              (err) => {
                if (err instanceof NetworkError) {
                  setCriticalError(M.NetworkError);
                }
                console.error(`could not load the task: ${err}`);
              }
            );

            // If this is the first assignment after loading.
            if (queue.length === 1 && !loaded) {
              setLoaded(true);
            }
          },
          (err) => {
            if (err instanceof NetworkError) {
              setLoaded(true);
              setCriticalError(M.NetworkError);
              return;
            }
            // FIXME: loaded may be in an inconsistent state at this point.
            setCriticalError(`error creating reservation: ${err}`);
          }
        );
    }
  }, [setLoaded, loaded, taskType, navigate, queue.length]);

  // Log current asignment.
  React.useEffect(() => {
    if (current !== undefined) {
      console.info("First TASK", current.reservation.id, current.task);
    }
  }, [current]);

  // Refresh current assignment.
  React.useEffect(() => {
    if (queue.length === 0) {
      setCurrent(undefined);
      return;
    }
    setCurrent(queue[0]);
  }, [queue]);

  const shiftQueue = () => {
    setQueue((q) => q.slice(1));
  };

  // const nextReservation = () => {
  //   setValidReservations((vrs) => vrs.slice(1));
  //   setValidTasks((vts) => vts.slice(1));
  // };

  const handleCompletion = (
    reservationID: string,
    payload: Completion
  ): void => {
    console.info(
      `Completing ${reservationID} with ${JSON.stringify(payload)}`,
      current
    );
    // Make a copy in case the next reservation changes the payload.
    const payloadCpy: Completion = JSON.parse(JSON.stringify(payload));

    // Go to the next reservation.
    shiftQueue();
    // setCurrent(queue[0])

    if (
      !Object.prototype.hasOwnProperty.call(payload, "type") ||
      (!hasProp(payload, "completion") && !hasProp(payload, "error"))
    ) {
      alert(
        "completion should have `type` and (`completion` or `error`) properties"
      );
      return;
    }

    axios
      .post(
        `/reservations/${reservationID}/complete`,
        {
          uid: userID,
          ...payloadCpy,
        },
        config()
      )
      .then(
        (resp) => {
          if (resp.data.error) {
            console.error("Completion FAILED", resp.data);
            // TODO: go to next reservation as well ?
          }
          console.info("Completion OK", reservationID);
        },
        (err) => {
          if (err instanceof NetworkError) {
            setCriticalError(M.NetworkError);
            console.error("network error", err);
            return;
          }
          console.error(err);
        }
      );
  };

  const handleBadInput = (reservationID: string, duration: number) => {
    console.info(`Reporting ${reservationID}`, current);
    // Go to next assignment.
    shiftQueue();

    axios
      .post(
        `/reservations/${reservationID}/bad-input`,
        {
          duration,
        },
        config()
      )
      .then(
        (resp) => {
          if (resp.status !== 201) {
            console.error("reporting bad input", resp);
          }
          console.info("Report OK", reservationID);
        },
        (err) => {
          console.error("reporting input error", err);
        }
      );
  };

  const handleWorkerStop = async () => {
    setStoppingWork(true);

    await Promise.all(
      queue.map(({ reservation }) =>
        axios.delete(`/reservations/${reservation.id}`, config())
      )
    );

    navigate("/app/tasks");
  };

  const handleTimeout = () => {
    alert("time out!");

    handleWorkerStop();
  };

  if (!loaded) {
    return <p>Loading tasks...</p>;
  }

  if (criticalError) {
    return <p>Critical error: {`${criticalError}`}</p>;
  }

  if (stoppingWork) {
    return <p>Redirecting...</p>;
  }

  return (
    <AppLayout>
      <Container maxWidth="xl" component="main">
        <CssBaseline />
        <Paper>
          {current !== undefined ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TopBar
                  expiration={current.reservation.expiresAt}
                  onStop={handleWorkerStop}
                  onTimeout={handleTimeout}
                />
              </Grid>
              <Grid item xs={12}>
                <CompleteReservation
                  t={current.task}
                  reservation={current.reservation}
                  onCompletion={handleCompletion}
                  onBadInput={handleBadInput}
                />
              </Grid>
            </Grid>
          ) : (
            <p>no more reservations</p>
          )}
        </Paper>
      </Container>
    </AppLayout>
  );
};
