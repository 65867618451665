export type Reservation = {
  id: string;
  taskId: string;
  workerId: string;
  createdAt: Date;
  expiresAt: Date;
};

export type TInput = {
  image?: string;
  text?: string;
  html?: string;
};

export type TClass = {
  label: string;
  desc?: string;
  value: string;
  hotkey?: string;
};

export type FType =
  | "FIELD_STR"
  | "FIELD_INT"
  | "FIELD_DOUBLE"
  | "FIELD_DATE"
  | "FIELD_MONEY"
  | "FIELD_REG_REF"
  | "FIELD_STREET_ADDR";

export type TField = {
  label: string;
  desc?: string;
  name: string;
  fieldType: FType;
  minCount: number;
  maxCount: number;
};

export type SegmentationTask = {
  id: string;
  taskType: string;
  instructions: string;
  taskInput: TInput;
  segmentClasses: TClass[];
  createdAt: Date;
  status: number;
  labels: Record<string, unknown>;
};

export type TranslationTask = {
  id: string;
  taskType: string;
  instructions: string;
  taskInput: TInput;
  fields: TField[];
  createdAt: Date;
  status: number;
  labels: Record<string, unknown>;
};

export type ClassificationTask = {
  id: string;
  taskType: string;
  instructions: string;
  taskInput: TInput;
  createdAt: Date;
  status: number;
  labels: Record<string, unknown>;
  classes: TClass[];
};

export type Task = SegmentationTask | TranslationTask | ClassificationTask;

export enum TaskType {
  Segmentation = "segmentation",
  Translation = "translation",
  Classification = "classification",
}

export const getType = (type: string): TaskType | null => {
  // FIXME: check that these types are correct when stablishing a convention.
  if (type.startsWith("segment")) {
    return TaskType.Segmentation;
  }
  if (type.startsWith("translat")) {
    return TaskType.Translation;
  }
  if (type.startsWith("classif")) {
    return TaskType.Classification;
  }
  return null;
};

export type Completion = {
  type: TaskType;
  duration: number;
  completion: Record<string, unknown>;
};
