import React from "react";
import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import axios from "axios";

import { AppLayout } from "../components/layout/AppLayout";
import { config } from "../api";

type User = {
  id: string;
  fname: string;
  lname: string;
  email: string;
  isActive: boolean;
  isRoot: boolean;
};

type UserGroup = {
  aid: string;
  group: string;
};

type Group = {
  group: string;
  descr: string;
};

type GroupList = {
  groups: Group[];
  userGroups: UserGroup[];
};

const GroupsList = function GroupsListSection({
  groups,
  onChange,
}: {
  groups: Group[];
  onChange: () => void;
}): React.ReactElement {
  const [newGroup, setNewGroup] = React.useState("");
  const [newDescr, setNewDescr] = React.useState("");
  const createGroup = () => {
    if (newGroup === "") {
      alert("empty new group");
    }
    axios
      .post("/groups", { name: newGroup, descr: newDescr }, config())
      .then((resp) => {
        if (resp.status !== 201) {
          alert(resp);
        }
        setNewDescr("");
        setNewGroup("");
        onChange();
      })
      .catch((err) => {
        alert(err);
      });
  };
  const deleteGroup = (group: string) => {
    if (group === "") {
      alert("empty");
    }
    axios
      .delete(`/groups/${group}`, config())
      .then((resp) => {
        if (resp.status !== 200) {
          alert(resp);
        }
        onChange();
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <div>
      <Typography variant="h4">Groups</Typography>
      <div>
        <TextField
          placeholder="group name"
          value={newGroup}
          onChange={(e) => setNewGroup(e.target.value)}
        />
        <TextField
          placeholder="group description"
          value={newDescr}
          onChange={(e) => setNewDescr(e.target.value)}
        />
        <Button onClick={createGroup}>Create</Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Ops</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.length === 0 ? <Typography>no groups</Typography> : null}
            {
              // eslint-disable-next-line react/destructuring-assignment
              groups.map(({ group, descr }) => (
                <TableRow key={group}>
                  <TableCell component="th" scope="row">
                    {group}
                  </TableCell>
                  <TableCell>{descr}</TableCell>
                  <TableCell>
                    <Button onClick={() => deleteGroup(group)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const UsersList = function UsersListSection({
  users,
  onChange,
}: {
  users: User[];
  onChange: () => void;
}): React.ReactElement {
  const [newFName, setNewFName] = React.useState("");
  const [newLName, setNewLName] = React.useState("");
  const [newEmail, setNewEmail] = React.useState("");

  const createUser = () => {
    if (newFName.length * newLName.length * newLName.length === 0) {
      alert("empty fields");
    }
    axios
      .post(
        "/users",
        { fname: newFName, lname: newLName, email: newEmail },
        config()
      )
      .then((resp) => {
        if (resp.status !== 201) {
          alert(resp);
        }
        setNewFName("");
        setNewLName("");
        setNewEmail("");
        onChange();
      })
      .catch((err) => {
        alert(err);
      });
  };

  const disableUser = (id: string) => {
    if (id === "") {
      alert("empty");
    }
    axios
      .post(`/users/${id}/disable`, {}, config())
      .then((resp) => {
        if (resp.status !== 200) {
          alert(resp);
        }
        onChange();
      })
      .catch((err) => {
        alert(err);
      });
  };

  const cancelAllReservations = (uid: string) => {
    if (uid === "") {
      alert("empty");
    }
    axios
      .delete(`/users/${uid}/reservations`, config())
      .then((resp) => {
        if (resp.status !== 200) {
          alert(resp);
        }
        onChange();
      })
      .catch((err) => {
        alert(err);
      });
  };

  const enableUser = (id: string) => {
    if (id === "") {
      alert("empty");
    }
    axios
      .post(`/users/${id}/enable`, {}, config())
      .then((resp) => {
        if (resp.status !== 200) {
          alert(resp);
        }
        onChange();
      })
      .catch((err) => {
        alert(err);
      });
  };

  const root = (id: string) => {
    if (id === "") {
      alert("empty");
    }
    alert("use a DB query");
    // axios
    //   .post(`/users/${id}/enable`, {}, config())
    //   .then((resp) => {
    //     if (resp.status !== 200) {
    //       alert(resp);
    //     }
    //     onChange();
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
  };

  const unroot = (id: string) => {
    if (id === "") {
      alert("empty");
    }
    alert("use a DB query");
    // axios
    //   .post(`/users/${id}/enable`, {}, config())
    //   .then((resp) => {
    //     if (resp.status !== 200) {
    //       alert(resp);
    //     }
    //     onChange();
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
  };

  return (
    <div>
      <Typography variant="h4">Users</Typography>
      <div>
        <TextField
          value={newFName}
          placeholder="first name"
          onChange={(e) => setNewFName(e.target.value)}
        />
        <TextField
          value={newLName}
          placeholder="last name"
          onChange={(e) => setNewLName(e.target.value)}
        />
        <TextField
          value={newEmail}
          placeholder="email"
          onChange={(e) => setNewEmail(e.target.value)}
        />
        <Button onClick={createUser}>Create</Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>FirstName</TableCell>
              <TableCell>LastName</TableCell>
              <TableCell>Active?</TableCell>
              <TableCell>Root?</TableCell>
              <TableCell>Cancel RS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length === 0 ? <Typography>no users</Typography> : null}
            {
              // eslint-disable-next-line react/destructuring-assignment
              users.map(({ id, fname, lname, email, isActive, isRoot }) => (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell component="th" scope="row">
                    {email}
                  </TableCell>
                  <TableCell>{fname}</TableCell>
                  <TableCell>{lname}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={isActive}
                      onChange={() =>
                        isActive ? disableUser(id) : enableUser(id)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={isRoot}
                      onChange={() => (isRoot ? unroot(id) : root(id))}
                    />
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => cancelAllReservations(id)}>X</Button>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

type Member = {
  email: string;
  group: string;
};

const UserGroupsList = function UserGroupList({
  userGroups,
  users,
  onChange,
}: {
  userGroups: UserGroup[];
  users: User[];
  onChange: () => void;
}): React.ReactElement {
  const [newGroup, setNewGroup] = React.useState("");
  const [newEmail, setNewEmail] = React.useState("");
  const [sortBy, setSortBy] = React.useState("group");
  const [members, setMembers] = React.useState<Member[]>([]);

  const createMember = () => {
    if (newGroup === "") {
      alert("empty new group");
    }
    axios
      .post(`/groups/${newGroup}/users/${newEmail}`, {}, config())
      .then((resp) => {
        if (resp.status !== 201) {
          alert(resp);
        }
        setNewEmail("");
        setNewGroup("");
        onChange();
      })
      .catch((err) => {
        alert(err);
      });
  };

  const deleteMember = (group: string, email: string) => {
    if (group === "") {
      alert("empty");
    }
    axios
      .delete(`/groups/${group}/users/${email}`, config())
      .then((resp) => {
        if (resp.status !== 200) {
          alert(resp);
        }
        onChange();
      })
      .catch((err) => {
        alert(err);
      });
  };

  React.useEffect(() => {
    const sortFn: Record<string, (a: Member, b: Member) => number> = {
      group: (a: Member, b: Member) => a.group.localeCompare(b.group),
      email: (a: Member, b: Member) => a.email.localeCompare(b.email),
    };

    const emails: Record<string, string> = {};

    users.forEach((u) => {
      emails[u.id] = u.email;
    });

    setMembers(
      userGroups
        .map((ug) => ({
          email: emails[ug.aid],
          group: ug.group,
        }))
        .sort(sortFn[sortBy])
    );
  }, [sortBy, userGroups, users]);

  return (
    <div>
      <Typography variant="h4">Members</Typography>
      <div>
        <TextField
          placeholder="user email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
        <TextField
          placeholder="group name"
          value={newGroup}
          onChange={(e) => setNewGroup(e.target.value)}
        />
        <Button onClick={createMember}>Create</Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => setSortBy("email")}>
                User {sortBy === "email" ? "▲" : ""}
              </TableCell>
              <TableCell onClick={() => setSortBy("group")}>
                Group {sortBy === "group" ? "▲" : ""}
              </TableCell>
              <TableCell>Ops</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.length === 0 ? <Typography>No members</Typography> : null}
            {
              // eslint-disable-next-line react/destructuring-assignment
              members.map(({ group, email }) => (
                <TableRow key={`${group}-${email}`}>
                  <TableCell component="th" scope="row">
                    {email}
                  </TableCell>
                  <TableCell>{group}</TableCell>
                  <TableCell>
                    <Button onClick={() => deleteMember(group, email)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export const AdminPage = function AdminPage(): React.ReactElement {
  const [isLoading, setIsLoading] = React.useState(true);
  const [groups, setGroups] = React.useState<GroupList>({
    groups: [],
    userGroups: [],
  });
  const [users, setUsers] = React.useState<User[]>([]);
  const [updates, setUpdates] = React.useState(0);

  const regUpdate = () => {
    setUpdates((u) => u + 1);
  };

  React.useEffect(() => {
    async function fetchData() {
      const getGroupsResp = await axios.get("/groups", config());
      const getUsersResp = await axios.get("/users", config());
      setGroups(getGroupsResp.data);
      setUsers(getUsersResp.data.users);
      setIsLoading(false);
    }
    fetchData();
  }, [updates]);

  return (
    <AppLayout>
      <Container component="main">
        <CssBaseline />
        <Typography variant="h3">Admin</Typography>
        {isLoading ? (
          <Typography variant="h4">Please wait, loading ...</Typography>
        ) : (
          <Stack spacing={2}>
            <UsersList users={users} onChange={regUpdate} />
            <GroupsList groups={groups.groups} onChange={regUpdate} />
            <UserGroupsList
              users={users}
              userGroups={groups.userGroups}
              onChange={regUpdate}
            />
          </Stack>
        )}
      </Container>
    </AppLayout>
  );
};
