import { KonvaEventObject } from "konva/lib/Node";
import React from "react";
import Konva from "konva";
import { Rect, Transformer } from "react-konva";
import { Box } from "@mui/material";

import { BBox } from "./common";

// const useStyles = makeStyles({
//   segmentItem: {
//     fontFamily: "monospace",
//     padding: 5,
//     margin: 5,
//     borderRadius: 3,
//     boxShadow: "0 0 3px grey",
//   },
// });

const SegmentListItem = function ({ s }: { s: BBox }) {
  return (
    <Box
      sx={{
        fontFamily: "monospace",
        padding: 5,
        margin: 5,
        borderRadius: 3,
        boxShadow: "0 0 3px grey",
      }}
    >
      <div>{s.k}</div>
      <div>
        (x,y): ({Number(s.x).toFixed(2)}, {Number(s.y).toFixed(2)})
      </div>
      <div>
        (w,h): ({Number(s.w).toFixed(2)}, {Number(s.h).toFixed(2)})
      </div>
    </Box>
  );
};

type SegmentsProps = {
  segments: BBox[];
};

export const SegmentsList = function ({
  segments,
}: SegmentsProps): React.ReactElement {
  return (
    <div>
      {segments.map((s) => (
        <SegmentListItem key={s.k} s={s} />
      ))}
    </div>
  );
};

type SegmentProps = {
  shapeProps: BBox;
  isSelected: boolean;
  onSelect: (e: KonvaEventObject<DragEvent>) => void;
  onChange: (a: BBox) => void;
};

export const Segment = function ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
}: SegmentProps): React.ReactElement {
  const shapeRef = React.useRef<Konva.Rect>(null);
  const trRef = React.useRef<Konva.Transformer>(null);

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      const ref = trRef.current;
      if (ref !== null && shapeRef.current !== null) {
        ref.nodes([shapeRef.current]);
        ref.getLayer()?.batchDraw();
      }
    }
  }, [isSelected]);

  return (
    <>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        x={shapeProps.x}
        y={shapeProps.y}
        width={shapeProps.w}
        height={shapeProps.h}
        id={shapeProps.k}
        draggable
        fill="lightyellow"
        stroke="black"
        opacity={0.5}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          if (node === null) {
            return;
          }
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            w: Math.max(5, node.width() * scaleX),
            h: Math.max(node.height() * scaleY),
          });
        }}
      />
      {isSelected && (
        <Transformer
          rotateEnabled={false}
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};
