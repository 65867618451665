import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AdminPage } from "./pages/Admin";
import { CompleteTaskPage } from "./pages/CompleteTaskPage";
import { SignInPage } from "./pages/SignIn";
import { TaskListPage } from "./pages/TaskListPage";
import { CompletionsPage } from "./pages/CompletionsPage";
// import { WithAPI } from "./WithAPI";

const PageNotFound = function () {
  return <p>Page not found! :-(</p>;
};

const DefaultLayout = function () {
  return (
    <>
      {/* <p>Default layout</p> */}
      <Outlet />
    </>
  );
};

export const routes = (isSignedIn: boolean) => [
  {
    path: "/",
    element: <Navigate to="/app" />,
    children: [
      { path: "404", element: <PageNotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/app",
    element: isSignedIn ? <DefaultLayout /> : <Navigate to="/auth/sign-in" />,
    children: [
      { index: true, element: <Navigate to="/app/tasks" /> },
      { path: "tasks", element: <TaskListPage /> },
      { path: "complete/:taskType", element: <CompleteTaskPage /> },
      { path: "completions/:group", element: <CompletionsPage /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/admin",
    element: isSignedIn ? <DefaultLayout /> : <Navigate to="/auth/sign-in" />,
    children: [{ path: "accounts", element: <AdminPage /> }],
  },
  {
    path: "/auth",
    element: !isSignedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      { path: "sign-in", element: <SignInPage /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];
