import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Link,
  Typography,
  TextField,
  Grid,
  Alert,
  Box,
} from "@mui/material";
import axios from "axios";
import shallow from "zustand/shallow";
import { useNavigate } from "react-router-dom";

import { User, useStore } from "../store";

type VerificationProps = {
  // eslint-disable-next-line react/require-default-props
  label?: string;
  handleVerification: (code: string, phone: string) => void;
};

const Verification = function ({
  label,
  handleVerification,
}: VerificationProps) {
  const [email, setEmail] = useState<string>("");
  const [vcode, setVCode] = useState<string>("");
  const [isEmailEditable, setIsEmailEditable] = useState<boolean>(true);
  const [showCode, setShowCode] = useState<boolean>(false);
  const [sendTime, setSendTime] = useState<number>(Date.now());
  const [disableVerify, setDisableVerify] = useState<boolean>(false);
  const [verificationError, setVerificationError] = useState<string>("");
  const [loading, setLoading] = React.useState(false);

  const handlePhoneInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (isEmailEditable) {
      setEmail(e.currentTarget.value);
    }
  };

  const handleSendValidation = (e: React.SyntheticEvent | null) => {
    setLoading(true);
    e?.preventDefault();
    setIsEmailEditable(false);

    axios.post("/auth/verify", { email }).then((resp) => {
      setLoading(false);
      if (resp.data.error) {
        setVerificationError(`Error creating the code: ${resp.data.error}`);
      }
      setShowCode(true);
      setSendTime(Date.now());
      setVCode("");
    });
  };

  const handleRetry = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const elapsed = Date.now() - sendTime;
    const waitSecs = 10;
    if (elapsed < waitSecs * 1000) {
      alert(`Espera ${waitSecs - Math.round(elapsed / 1000)} s`);
      return;
    }
    handleSendValidation(null);
  };

  const handleEmailChange = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowCode(false);
    setVCode("");
    setEmail("");
    setIsEmailEditable(true);
    setVerificationError("");
  };

  const handleVerificationButt = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    handleVerification(vcode, email);
    setDisableVerify(true);
    setLoading(false);
  };

  if (loading) {
    return <Typography>loading... please wait</Typography>;
  }

  return (
    <Box
      sx={{
        // marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <TextField
            label="Seu email"
            placeholder=""
            type="email"
            value={email}
            onChange={handlePhoneInputChange}
            disabled={!isEmailEditable}
          />
        </Grid>
        {isEmailEditable ? (
          <Grid item xs={5}>
            <Button
              onClick={handleSendValidation}
              fullWidth
              variant="contained"
              color="primary"
            >
              Send Code
            </Button>
          </Grid>
        ) : null}
        {isEmailEditable && verificationError !== "" ? (
          <Alert severity="error">{verificationError}</Alert>
        ) : null}
        {showCode ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body2">
                Escreva o código enviado ao email {email}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="vcode"
                name="vcode"
                label="verification code"
                value={vcode}
                onChange={(e) => setVCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleVerificationButt}
                disabled={disableVerify}
              >
                {label || "Verify"}
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button onClick={handleRetry} disabled={disableVerify}>
                Reenviar código
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button onClick={handleEmailChange}>Trocar email</Button>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
};

export const SignInPage = function (): React.ReactElement {
  const [alertMsg, setAlertMsg] = React.useState<string>("");

  const navigate = useNavigate();
  const [token, setUser] = useStore(
    (state) => [state.user.token, state.setUser],
    shallow
  );

  useEffect(() => {
    if (token !== "") {
      console.error("sip:token", token);
      navigate("/");
    }
  }, [navigate, token]);

  const handleVCode = (vtoken: string, email: string) => {
    axios.post("/auth/sign-in", { email, vtoken }).then(
      (resp) => {
        if (resp.data.error) {
          setAlertMsg(`could not sign in: ${resp.data.error}`);
          return;
        }
        setUser(resp.data as User);
      },
      (err) => {
        setAlertMsg(`could not sign in: ${err}`);
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {alertMsg !== "" ? <Alert severity="error">{alertMsg}</Alert> : null}
        <Typography variant="h3">Sign in</Typography>
        <Typography variant="body1">
          You must login to view that page.
        </Typography>
        <form>
          <Verification label="Sign In" handleVerification={handleVCode} />
        </form>
        <Typography variant="caption">
          Don&apos;t have an account?{" "}
          <Link href="/#/auth/sign-up">Sign-up</Link>
        </Typography>
      </Box>
    </Container>
  );
};
