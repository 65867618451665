import React from "react";
import { Link, Typography, Button, Toolbar, AppBar, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import { useStore } from "../../store";

const Copyright = function (): React.ReactElement {
  return (
    <Box sx={{ paddingTop: 10 }}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://material-ui.com/">
          Trular
        </Link>{" "}
        {new Date().getFullYear()}.
      </Typography>
    </Box>
  );
};

const NavBar = function (): React.ReactElement {
  const navigate = useNavigate();
  const [email, clearUser] = useStore(
    (state) => [state.user.email, state.clearUser],
    shallow
  );
  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
        <Link href="/#/app" color="inherit" underline="none">
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Trular Labelling
          </Typography>
        </Link>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <nav>
            {/* <Link
          variant="button"
          color="text.primary"
          href="#/"
          sx={{ my: 1, mx: 1.5 }}
        >
          Features
        </Link>
        <Link
          variant="button"
          color="text.primary"
          href="#/"
          sx={{ my: 1, mx: 1.5 }}
        >
          Enterprise
        </Link> */}
            <Link
              variant="button"
              color="text.primary"
              href={`/#/app/completions/${email}`}
              sx={{ my: 1, mx: 1.5 }}
            >
              Summary
            </Link>
          </nav>
          <Typography>{email}</Typography>
          <Button
            onClick={() => {
              clearUser();
              navigate("/");
            }}
            variant="outlined"
            sx={{ my: 1, mx: 1.5 }}
          >
            Log out
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

type AppLayoutProps = {
  children: React.ReactNode;
};

export const AppLayout = function ({
  children,
}: AppLayoutProps): React.ReactElement {
  return (
    <>
      <NavBar />
      {children}
      <Copyright />
    </>
  );
};
