import React from "react";
import { KonvaEventObject } from "konva/lib/Node";
import { Stage, Layer, Image } from "react-konva";
import Konva from "konva";
import useImage from "use-image";
import { Button, Box, ButtonGroup } from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";

import { BBox } from "./common";
import { Segment } from "./Segments";
import { Completion, getType, SegmentationTask, TaskType } from "../../tasks";

const relPtrPos = (node: Konva.Stage | null) => {
  if (node === null) {
    return null;
  }
  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const pos = node.getStage().getPointerPosition();
  if (pos === null) {
    return null;
  }
  return transform.point(pos);
};

type DrawSegmentsProps = {
  task: SegmentationTask;
  reservationID: string;
  onCompletion: (id: string, spec: Completion) => void;
  onBadInput: (id: string, duration: number) => void;
  height: number;
};

export const SegmentImage = function SegmentImage({
  task,
  reservationID,
  onCompletion,
  onBadInput,
  height,
}: DrawSegmentsProps): React.ReactElement {
  const [boxes, setBoxes] = React.useState<BBox[]>([]);
  const [newBox, setNewBox] = React.useState<BBox>();
  const [isAdding, setIsAdding] = React.useState<boolean>(false);
  const [selectedID, setSelectedID] = React.useState<string | null>(null);
  const [t0State, setT0State] = React.useState<Date>(new Date(Date.now()));
  const completeBtnRef = React.createRef<HTMLButtonElement>();
  const [locked, setLocked] = React.useState(false);

  React.useEffect(() => {
    setBoxes([]);
    setNewBox(undefined);
    setIsAdding(false);
    setSelectedID(null);
    setT0State(new Date(Date.now()));
    setLocked(false);
  }, [reservationID]);

  const handleMDown = React.useCallback(
    // Store the initial corner of the box.
    (e: KonvaEventObject<MouseEvent>) => {
      const stage = e.target.getStage();
      const pp = relPtrPos(stage); // stage?.getPointerPosition();
      if (
        !isAdding ||
        newBox !== undefined ||
        stage === null ||
        pp === null ||
        pp === undefined
      ) {
        return;
      }
      const { x, y } = pp;
      setNewBox({ x, y, w: 0, h: 0, k: boxes.length.toString() });
    },
    [isAdding, newBox, boxes.length]
  );

  React.useEffect(() => {
    completeBtnRef.current?.focus();
  }, [completeBtnRef, boxes]);

  const handleMUp = React.useCallback(
    // Calculate the size of the box and add it to the list.
    (e) => {
      if (!isAdding || newBox === undefined) {
        return;
      }
      const pp = relPtrPos(e.target.getStage());
      if (pp === null) {
        return;
      }
      const { x, y } = pp; // e.target.getStage().getPointerPosition();
      const finalBox = {
        // x: newBox.x,
        // y: newBox.y,
        // w: x - newBox.x,
        // h: y - newBox.y,
        x: Math.min(x, newBox.x),
        y: Math.min(y, newBox.y),
        w: Math.abs(x - newBox.x),
        h: Math.abs(y - newBox.y),
        k: `segment-${newBox.k}`,
        // k: "segment",
      };
      setNewBox(undefined);
      setIsAdding(false);
      // Add to the list _wide enough_ boxes.
      if (Math.abs(finalBox.w) > 300) {
        setBoxes((oldBoxes) => [...oldBoxes, finalBox]);
      }
    },
    [isAdding, newBox]
  );

  const handleMMove = React.useCallback(
    (e) => {
      if (!isAdding || newBox === undefined) {
        return;
      }
      const pp = relPtrPos(e.target.getStage());
      if (pp === null) {
        return;
      }
      const { x, y } = pp; // e.target.getStage().getPointerPosition();
      setNewBox({
        x: newBox.x,
        y: newBox.y,
        w: x - newBox.x,
        h: y - newBox.y,
        k: newBox.k,
      });
    },
    [newBox, isAdding]
  );

  const handleAddBox = React.useCallback(
    () => setIsAdding(!isAdding),
    [isAdding]
  );

  const handleCompletion = React.useCallback(() => {
    if (locked) {
      return;
    }
    setLocked(true);
    onCompletion(reservationID, {
      type: TaskType.Segmentation,
      duration: Math.abs((Date.now() - t0State.getTime()) / 1000),
      completion: {
        segments: boxes.map((bs) => ({
          bbox: { x: bs.x, y: bs.y, h: bs.h, w: bs.w },
          // FIXME: only the first class in the spec is used.
          classValue: task.segmentClasses[0].value,
        })),
      },
    });
  }, [boxes, onCompletion, reservationID, t0State, task.segmentClasses]);

  const handleError = () => {
    if (locked) {
      return;
    }
    setLocked(true);

    onBadInput(
      reservationID,
      Math.abs((Date.now() - t0State.getTime()) / 1000)
    );
  };

  const addBoxHotkey = task.segmentClasses[0].hotkey || "a";

  useHotkeys(addBoxHotkey, () => setIsAdding(true));

  useHotkeys("ctrl+a", () => {
    setIsAdding(false);
    setNewBox(undefined);
  });

  useHotkeys(
    "Backspace",
    () => {
      setBoxes((oldBoxes) => oldBoxes.filter((b) => b.k !== selectedID));
    },
    [selectedID]
  );
  useHotkeys("escape", () => setSelectedID(null));
  // useHotkeys("enter, space", () => handleCompletion(), undefined, [
  //   boxes,
  //   onCompletion,
  //   reservationID,
  //   t0State,
  //   task.segmentClasses,
  // ]);

  const boxesInStage = newBox !== undefined ? [...boxes, newBox] : boxes;

  const imageURL = task.taskInput.image || "";

  const [image] = useImage(imageURL, "anonymous");

  if (image === undefined) {
    return <>.</>;
    // return <p>{`Couldn't load image "${imageURL}"`}</p>;
  }

  if (getType(task.taskType) !== TaskType.Segmentation) {
    return (
      <p>Not a segmentation task! Task type is {getType(task.taskType)}</p>
    );
  }

  const imageComp = <Image image={image} />;
  const scale = height / image.height;
  const ratio = image.width / image.height;

  const addButtonLabel = isAdding ? "adding" : `[${addBoxHotkey}] add`;

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: 300,
          padding: 5,
          position: "fixed",
          right: 0,
          top: "50%",
          marginTop: "-2.5em",
        }}
      >
        {/* <SegmentsList segments={boxes} /> */}
        {/* <span>selected: {selectedID}</span> */}
        <ButtonGroup variant="contained" orientation="vertical">
          <Button
            onClick={handleAddBox}
            color={isAdding ? "secondary" : "primary"}
            variant="contained"
          >
            {addButtonLabel}
            {/* {isAdding ? "adding" : "add box"} */}
          </Button>
          <Button
            variant="contained"
            onClick={handleCompletion}
            ref={completeBtnRef}
          >
            Complete
          </Button>
          <Button color="error" variant="contained" onClick={handleError}>
            report error
          </Button>
        </ButtonGroup>
      </Box>
      <Box
        sx={{
          width: "calc(100vw - 300px)",
          margin: 5,
          marginTop: 0,
          position: "relative",
        }}
      >
        <Stage
          sx={{
            boxShadow: "0 0 5px grey",
          }}
          width={ratio * height}
          height={height}
          scale={{ x: scale, y: scale }}
          onMouseDown={handleMDown}
          onMouseUp={handleMUp}
          onMouseMove={handleMMove}
        >
          <Layer>
            {imageComp}
            {boxesInStage.map((b, ix) => (
              <Segment
                key={b.k}
                shapeProps={b}
                isSelected={b.k === selectedID}
                onSelect={() => {
                  setSelectedID(b.k);
                }}
                onChange={(updated) => {
                  const bCopy = boxes.slice();
                  bCopy[ix] = updated;
                  setBoxes(bCopy);
                }}
              />
            ))}
          </Layer>
        </Stage>
      </Box>
    </Box>
  );
};
