import React from "react";
import { Box, Typography } from "@mui/material";
// import { Image } from "react-konva";
// import useImage from "use-image";
import { TInput } from "../tasks";

const Img = function ({ imageURL }: { imageURL: string }) {
  // const [image] = useImage(imageURL);
  // if (image === undefined) {
  //   return null;
  // }
  return (
    <img
      src={imageURL}
      style={{
        maxHeight: 1100,
        maxWidth: 830,
        paddingLeft: 10,
        paddingRight: 10,
      }}
      alt="rendered in text"
    />
  );
};

const Txt = function ({ text }: { text: string }) {
  return <Typography variant="caption">{text}</Typography>;
};

const Html = function ({ html }: { html: string }) {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export const InputDisplay = function ({
  input,
}: {
  input: TInput;
}): React.ReactElement {
  const { image, text, html } = input;
  if (image === undefined && text === undefined && html === undefined) {
    return <p>INVALID INPUT: no content</p>;
  }
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
      {image ? (
        <Box sx={{}}>
          <Img imageURL={image} />
        </Box>
      ) : null}
      <Box>
        {html ? <Html html={html} /> : null}
        {text ? <Txt text={text} /> : null}
      </Box>
    </Box>
  );
};
